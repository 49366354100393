<template>
	<div>
		<header class="mb-5">
			<nav class="navbar navbar-expand-lg px-5">
				<a class="navbar-brand mx-auto" href="#">
					<img :src="logo" alt="bosCOD | Platform Kurir COD/Non-COD" width="auto" height="50">
				</a>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav mx-auto">
						<li class="nav-item">
							<a class="nav-link" href="https://boscod.com/#layanan">Layanan</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="https://boscod.com/#alur">Alur Pengiriman</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" :href="url + '/ongkir'">Cek Ongkir</a>
						</li>
						<li class="nav-item active">
							<a class="nav-link" :href="url + '/lacak'">Lacak</a>
						</li>
					</ul>
					<div class="form-inline d-flex justify-content-around my-2 my-lg-0">
						<a class="btn btn-outline-primary rounded my-2 my-sm-0 mx-2 text-primary" href="login">Masuk</a>
						<a class="btn btn-primary rounded my-2 my-sm-0 mx-2 text-light" href="order/create/single">Mulai Kirim</a>
					</div>
				</div>
			</nav>
		</header>
		<main role="main" class="mt-5">
			<section class="container">
				<h3 class="font-weight-bold px-4 px-sm-0">Cek Resi</h3>
				<form role="form" @submit="submitForm" class="card border rounded">
					<div class="card-body">
						<div class="row">
							<div class="col-12 col-sm form-group mx-2">
								<label for="no_resi">No Resi<span class="text-danger">*</span></label>
								<input type="text" name="no_resi" id="no_resi" v-model="orderCode" class="form-control rounded" placeholder="Masukkan nomor resi, pisahkan dengan koma" required />
							</div>
							<div class="col-auto form-group mx-2 d-flex align-items-end">
								<button type="submit" name="submit" id="submit" class="btn btn-primary rounded">Cek Resi</button>
							</div>
						</div>
					</div>
				</form>
				<div class="row px-4 px-sm-0" v-if="info.visible">
					<div class="col-12 col-sm-auto d-flex">
						<img :src="getKurirLogo(info.courier_code)" class="d-none d-sm-block w-75 m-sm-auto">
						<img :src="getKurirLogo(info.courier_code)" class="d-block d-sm-none mw-100 m-sm-auto">
					</div>
					<div class="col-auto">
						<h4 class="my-3 font-weight-bold">No Resi<span class="d-block d-sm-inline my-3 my-sm-0 ml-sm-3 text-muted">{{ info.no_resi }}</span></h4>
						<h4 class="my-3 font-weight-bold text-muted">{{ info.courier_name }} &#8212; {{ info.courier_service_code }}</h4>
					</div>
				</div>
				<div class="card border rounded" v-if="info.visible">
					<div class="card-body">
						<div class="row">
							<div class="col-md-6 px-md-4">
								<div class="row">
									<div class="col-12 mb-4">
										<h5 class="border-bottom pb-3 mb-2 font-weight-bold">Data Pengiriman</h5>
										<div class="row pt-3">
											<div class="col-3">
												<p class="h6 d-inline bg-none rounded font-weight-bold d-flex justify-content-between"><span>Pengirim</span><span>:</span></p>
											</div>
											<div class="col">
												<p class="h6 py-2 px-3 d-inline bg-primary rounded font-weight-bold">{{ info.sender_name }}</p>
												<p class="h6 mt-3 text-wrap">{{ info.kec }}, {{ info.kab }}</p>
											</div>
										</div>
										<div class="row pt-3">
											<div class="col-3">
												<p class="h6 d-inline bg-none rounded font-weight-bold d-flex justify-content-between"><span>Penerima</span><span>:</span></p>
											</div>
											<div class="col">
												<p class="h6 py-2 px-3 d-inline bg-success rounded font-weight-bold">{{ info.receiver_name }}</p>
												<p class="h6 mt-3 text-wrap">{{ info.receiver_kecamatan }}, {{ info.receiver_kota }}</p>
											</div>
										</div>
										<div class="row pt-3">
											<div class="col-3">
												<p class="h6 d-inline bg-none rounded font-weight-bold d-flex justify-content-between"><span>Status</span><span>:</span></p>
											</div>
											<div class="col">
												<p :class="'h6 py-2 px-3 d-inline bg-' + info.statusType + ' rounded font-weight-bold'">{{ info.statusMsg }}</p>
											</div>
										</div>
									</div>
									<div class="col-12 mb-4" v-if="false">
										<h5 class="border-bottom pb-3 mb-2 font-weight-bold">Status Pengiriman</h5>
										<div class="row pt-2">
											<div class="col-4 pr-track">
												<div class="status-wrapper">
													<div class="status">Menunggu<br />Pickup</div>
												</div>
												<div class="line-wrapper">
													<div class="circle"></div>
													<div class="line"></div>
												</div>
											</div>
											<div class="col-4 pr-track">
												<div class="status-wrapper">
													<div class="status">&nbsp;<br />Perjalanan</div>
												</div>
												<div class="line-wrapper">
													<div class="circle"></div>
													<div class="line"></div>
												</div>
											</div>
											<div class="col-4 pr-track">
												<div class="status-wrapper">
													<div class="status">&nbsp;<br />Terkirim</div>
												</div>
												<div class="line-wrapper">
													<div class="circle"></div>
													<div class="line"></div>
												</div>
											</div>
											<div class="col-4 pr-track">
												<div class="status-wrapper">
													<div class="status">Proses<br />Retur</div>
												</div>
												<div class="line-wrapper">
													<div class="circle"></div>
													<div class="line"></div>
												</div>
											</div>
											<div class="col-4 pr-track">
												<div class="status-wrapper">
													<div class="status">Retur<br />Diserahkan</div>
												</div>
												<div class="line-wrapper">
													<div class="circle"></div>
													<div class="line"></div>
												</div>
											</div>
											<div class="col-4 pr-track">
												<div class="status-wrapper">
													<div class="status">Paket<br />Bermasalah</div>
												</div>
												<div class="line-wrapper">
													<div class="circle"></div>
													<div class="line"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 px-md-4">
								<h5 class="border-bottom pb-3 mb-2 font-weight-bold">Detail Pengiriman</h5>
								<div class="row pt-2">
									<div class="col-12 mb-4 overflow-auto" style="max-height: 300px;">
										<div class="ls-track" v-for="row in history">
											<div class="line-wrapper">
												<div class="circle"></div>
												<div class="line"></div>
											</div>
											<div class="status-wrapper">
												<div class="date">{{ row.date + " " + row.time }}</div>
												<div class="status">{{ removeUrl(row.note) }} {{ row.branch }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>
<script>
import Vue from 'vue'
import $ from "jquery";
import Swal from "sweetalert2";
export default {
	name: "Cek Resi",
	data() {
		return {
			url: window.location.origin,
			logo: '',
			orderCode: "",
			info: {
				courier_code: '',
				no_resi: '',
				courier_name: '',
				courier_service_code: '',
				sender_name: '',
				sender_address: '',
				receiver_name: '',
				receiver_address: '',
				statusType: '',
				statusMsg: '',
				visible: false,
			},
			history: [{
				date: '',
				time: '',
				note: '',
				branch: '',
			}],
		};
	},
	created: function(){
		if(Vue.config.appHost == 'xpress')
			this.logo = require(`../assets/img/logo-xpress.png`);
		else this.logo = require(`../assets/img/logo-min.svg`);
	},
	methods: {
		getKurirLogo(kurir) {
			const fileName = kurir.toLowerCase();
			return require(`../assets/img/${fileName}.png`); // the module request
		},
		submitForm: function (ev) {
			if (this.$route.params.id) window.open(this.orderCode, '_self');
			else window.open('lacak/' + this.orderCode, '_self');
			ev.preventDefault();
		},
		loadInfo: function (code) {
			fetch(window.$apiUrl + "/publik/lacak/" + code).then((res) => {
				res.json().then((json) => {
					if (json.success) {
						this.formatData(json.data);
					}
				});
			});
		},
		loadHistory: function (code) {
			fetch(window.$apiUrl + "/publik/track/" + code).then((res) => {//+'?expand='+(this.expand?1:0)
				res.json().then((json) => {
					if (json.success) {
						this.history = json.data;
					}
				});
			});
		},
		formatData: function (info) {
			if (info.pickup_status == 0) {
				if (info.status == 1) {
					info.statusMsg = "Error";
					info.statusType = "danger";
				} else {
					info.statusMsg = "Draft";
					info.statusType = "secondary";
				}
			} else if (info.tracking_status == 0) {
				if (info.order_type == 1){
					info.statusMsg = "Menunggu Drop";
				} else {
					info.statusMsg = "Menunggu Pickup";
				}
				info.statusType = "secondary";
			} else if (info.tracking_status == 1) {
				info.statusMsg = "Perjalanan";
				info.statusType = "warning";
			} else if (info.tracking_status == 2) {
				info.statusMsg = "Terkirim";
				info.statusType = "success";
			} else if (info.tracking_status == 3) {
				info.statusMsg = "Proses Retur";
				info.statusType = "warning";
			} else if (info.tracking_status == 4) {
				if (info.return_received == 0) {
					info.statusMsg = "Retur Diserahkan";
					info.statusType = "info";
				} else {
					info.statusMsg = "Retur Diterima";
					info.statusType = "danger";
				}
			} else if (info.tracking_status == 5) {
				info.statusMsg = "Paket Bermasalah";
				info.statusType = "danger";
			}
			info.feecod_shipper = parseInt(info.feecod_shipper);
			info.ppncod_shipper = parseInt(info.ppncod_shipper);
			info.visible = true;
			this.info = info;
		},
		removeUrl: function (text) {
			var urlRegex = /(https?:\/\/[^\s]+)/g;
			return text.replace(urlRegex, '<LOGIN UNTUK MELIHAT URL>');
		}
	},
	mounted() {
		if (this.$route.params.id) {
			this.orderCode = this.$route.params.id;
			this.loadInfo(this.orderCode);
			this.loadHistory(this.orderCode);
		}
	}
};
$(function () {
});
</script>
<style scoped src="../assets/login.css"></style>
<style scoped>
:root {
	--text-dark: #7A7A7A;
}
.navbar-nav .nav-item .nav-link {
	color: var(--text-dark) !important;
	font-weight: normal;
}
@media screen and (max-width: 767px) {
	.navbar-nav > li {
		width: auto;
	}
}
.navbar-nav .nav-item.active .nav-link {
	color: var(--text-dark) !important;
	font-weight: bold;
}
.pr-track {
	width: 100%;
	vertical-align: top;
	display: flex;
	flex-direction: column;
	min-height: 60px;
	padding-right: 0;
	padding-left: 0;
}
.ls-track {
	width: 100%;
	vertical-align: top;
	display: flex;
	min-height: 60px;
}
.pr-track .line-wrapper {
	flex-basis: 0px;
	margin: 16px 0px;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	display: flex;
}
.ls-track .line-wrapper {
	flex-basis: 0px;
	margin: 0px 16px;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	width: 3%;
	display: flex;
}
.pr-track:nth-child(1) .circle {
	background-color: transparent;
	animation: blinker 1s linear infinite;
}
.ls-track:first-child .circle {
	background-color: transparent;
	animation: blinker 1s linear infinite;
}
.pr-track .circle {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	position: relative;
	margin-top: 0.25rem;
	margin-bottom: auto;
	background-color: var(--N100, #dbdee2);
}
.ls-track .circle {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	position: relative;
	margin-top: 0.25rem;
	margin-bottom: auto;
	background-color: var(--N100, #dbdee2);
}
.pr-track .line-wrapper .line {
	height: 1px;
	width: calc(100% - 9px);
	margin-top: -7.5px;
	background-image: linear-gradient(
		to right,
		var(--N100, #dbdee2) 55%,
		rgba(255, 255, 255, 0) 55%
	);
	background-position: left middle;
	background-size: 12px 1px;
	background-repeat: repeat-x;
}
.ls-track .line-wrapper .line {
	width: 1px;
	height: calc(100% - 9px);
	margin-bottom: -6px;
	background-image: linear-gradient(
		to bottom,
		var(--N100, #dbdee2) 55%,
		rgba(255, 255, 255, 0) 55%
	);
	background-position: left center;
	background-size: 1px 12px;
	background-repeat: repeat-y;
}
.ls-track:first-child .status-wrapper {
	padding-bottom: 36px;
}
.pr-track:nth-child(1) .status-wrapper .status {
	font-weight: 800;
	line-height: 1.4;
}
.ls-track:first-child .status-wrapper .status {
	font-weight: 800;
	line-height: 1.4;
}
.pr-track .status-wrapper .status {
	color: var(--N500, #52565e);
	line-height: 1.5;
	text-align: center;
}
.ls-track .status-wrapper .status {
	color: var(--N500, #52565e);
	line-height: 1.5;
	margin-top: 8px;
}
.ls-track .status-wrapper .date {
	color: var(--N200, #9fa6b0);
}
.pr-track:nth-child(1) .circle::before , .ls-track:first-child .circle::before {
	width: 15px;
	position: absolute;
	height: 15px;
	border-radius: 50%;
	background-color: var(--G300, #f9b79d);
	transition: all 0.5s ease-out 0s;
	animation-name: grow;
	animation-duration: 1400ms;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
	animation-fill-mode: none;
	animation-play-state: running;
	content: "";
}
.pr-track:nth-child(1) .circle::after , .ls-track:first-child .circle::after {
	border-radius: 50%;
	background-color: var(--G500, #e96936);
	content: "";
	position: absolute;
	width: 11px;
	height: 11px;
	left: 2px;
	top: 2px;
}
.pr-track .circle::after , .ls-track .circle::after {
	border-radius: 50%;
	background-color: var(--N200, #9fa6b0);
	content: "";
	position: absolute;
	width: 9px;
	height: 9px;
	left: 3px;
	top: 3px;
}
.ls-track:last-of-type .line {
	display: none;
}
</style>
